<script>
import Layout from "../../layouts/main";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      folioFiscal: "",
      rfcEmisor: "",
      rfcReceptor: "",
      precio: "",
      razon_social_emisor: "",
      razon_social_receptor: "",
      fechaExpedicion: "",
      pac_que_certifico: "",
      fecha_certificacion_sat: "",
      total_del_cfdi: "",
      efecto_del_comprobante: "Traslado",
      estadoCfdi: "Vigente",
      estatus_de_cancelacion: "",
      responseUrl: "", // Para almacenar la URL de respuesta del servidor
      qrData: "", // Para almacenar los datos del QR
      verifica_domain: process.env.VUE_APP_VERIFICA_DOMAIN,
    };
  },
  watch: {
  },
  computed: {
    qrCodeUrl() {
      return `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(this.responseUrl)}`;
    },
    formValido() {
  return (
    this.folioFiscal !== '' &&
    this.rfcEmisor !== '' &&
    this.rfcReceptor !== '' &&
    this.razonSocialEmisor !== '' &&
    this.razonSocialReceptor !== '' &&
    this.fechaExpedicion !== '' &&
    this.fechaCertificacionSat !== '' &&
    this.total_del_cfdi !== '' &&
    this.efectoDelComprobante !== '' &&
    this.estadoCfdi !== '' &&
    this.estatus_de_cancelacion !== '' &&
    this.pac_que_certifico !== '' 
  );
},
  },
  
  methods: {
    async crearCarpeta() { this.cargando = true; // Iniciar la carga
  
  // Aquí puedes realizar la solicitud POST a tu endpoint
  try {
    const response = await fetch(`https://${this.verifica_domain}/connection/new_folder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      

      body: JSON.stringify({
        folioFiscal: this.folioFiscal.toUpperCase(),
        rfcEmisor: this.rfcEmisor.toUpperCase(),
        rfcReceptor: this.rfcReceptor.toUpperCase(),
        precio: this.precio,
        razon_social_emisor: this.razon_social_emisor.toUpperCase(),
        razon_social_receptor: this.razon_social_receptor.toUpperCase(),
        fechaExpedicion: this.fechaExpedicion.toUpperCase(),
        pac_que_certifico: this.pac_que_certifico.toUpperCase(),
        fecha_certificacion_sat: this.fecha_certificacion_sat,
        total_del_cfdi: "$"+this.total_del_cfdi,
        efecto_del_comprobante: this.efecto_del_comprobante[0].toUpperCase() + this.efecto_del_comprobante.slice(1),
        estadoCfdi: this.estadoCfdi[0].toUpperCase() + this.estadoCfdi.slice(1),
        estatus_de_cancelacion: this.estatus_de_cancelacion[0].toUpperCase() + this.estatus_de_cancelacion.slice(1),
      }),
    });
    
    const data = await response.json();
    this.responseUrl = data.url;
    
  } catch (error) {
    console.error('Error al enviar la solicitud:', error);
  } finally {
    this.cargando = false; // Finalizar la carga
  }
    },
    downloadQR() {
      const link = document.createElement("a");
      link.href = this.qrCodeUrl;
      link.download = "qrCode.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
   
  },
};
</script>

<template>
  <Layout>
    <div class="col-xl-12">
      <h5 class="title-home">Factura electrónica<span class="dot-home">.</span></h5>
      <!-- Formulario -->
      <form @submit.prevent="crearCarpeta">
        <!-- Primer conjunto de campos -->
        <div style="float:left; width:32%; margin-right: 2%; padding-bottom: 20px;">
          <label for="folioFiscal">Folio fiscal:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="folioFiscal" placeholder="" v-model="folioFiscal"/>
        </div>
        <div style="float:left; width:32%; margin-right: 2%; padding-bottom: 20px;">
          <label for="rfcEmisor">RFC emisor:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="rfcEmisor" placeholder="" v-model="rfcEmisor"/>
        </div>
        <div style="float:left; width:32%; padding-bottom: 20px;">
          <label for="rfcReceptor">RFC receptor:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="rfcReceptor" placeholder="" v-model="rfcReceptor"/>
        </div>
        <div style="clear:both;"></div>
        
        <!-- Segundo conjunto de campos -->
        <div style="float:left; width:32%; margin-right: 2%; padding-bottom: 20px;">
          <label for="razon_social_emisor">Nombre o razón social del emisor:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="razon_social_emisor" placeholder="" v-model="razon_social_emisor"/>
        </div>
        <div style="float:left; width:32%; margin-right: 2%; padding-bottom: 20px;">
          <label for="razon_social_receptor">Nombre o razón social del receptor:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="razon_social_receptor" placeholder="" v-model="razon_social_receptor"/>
        </div>
        <div style="float:left; width:32%; padding-bottom: 20px;">
          <label for="fechaExpedicion">Fecha de expedición:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="fechaExpedicion" placeholder="" v-model="fechaExpedicion"/>
        </div>
        <div style="clear:both;"></div>
        
        <!-- Tercer conjunto de campos -->
        <div style="float:left; width:32%; margin-right: 2%; padding-bottom: 20px;">
          <label for="fecha_certificacion_sat">Fecha certificación SAT:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="fecha_certificacion_sat" placeholder="" v-model="fecha_certificacion_sat"/>
        </div>
        <div style="float:left; width:32%; margin-right: 2%; padding-bottom: 20px;">
          <label for="pac_que_certifico">PAC que certificó:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="pac_que_certifico" placeholder="" v-model="pac_que_certifico"/>
        </div>
        <div style="float:left; width:32%; padding-bottom: 20px;">
          <label for="total_del_cfdi">Total del CFDI:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="total_del_cfdi" v-model="total_del_cfdi" />
                </div>
        
        <!-- Cuarto conjunto de campos -->
        <div style="float:left; width:32%; margin-right: 2%; padding-bottom: 20px;">
          <label for="efecto_del_comprobante">Efecto del comprobante:<span style="color:#247ba0">*</span></label>
          <input type="text" class="form-control" id="efecto_del_comprobante" placeholder="" v-model="efecto_del_comprobante"/>
        </div>
        <div style="float:left; width:32%; margin-right: 2%; padding-bottom: 20px;">
    <label for="estadoCfdi">Estado CFDI:<span style="color:#247ba0">*</span></label>
    <select class="form-control" id="estadoCfdi" v-model="estadoCfdi" @change="disableSelectEstado">
        <option value="" disabled>Seleccionar</option>
        <option value="Vigente">Vigente</option>
        <option value="Cancelado">Cancelado</option>
    </select>
</div>

        <div style="float:left; width:32%; padding-bottom: 20px;">
    <label for="estatus_de_cancelacion">Estatus de cancelación:<span style="color:#247ba0">*</span></label>
    <select class="form-control" id="estatus_de_cancelacion" v-model="estatus_de_cancelacion" @change="disableSelect">
        <option value="" disabled>Seleccionar</option>
        <option value="Cancelable sin aceptación">Cancelable sin aceptación</option>
        <option value="Cancelable con aceptación">Cancelable con aceptación</option>
        <option value="Cancelable con aceptación">No cancelable</option>
    </select>
</div>
        
        <!-- Botón para enviar el formulario -->
        <button v-if="!cargando" :disabled="!formValido || cargando" type="submit" class="btn button-client-form btn-primary">
          <span v-if="!cargando">CREAR NUEVO</span>
          <span v-else>
            <i class="fa fa-spinner fa-spin"></i> Cargando...
          </span>
        </button>      </form>
      
      <!-- Mostrar la URL de respuesta y el QR -->
      <div v-if="responseUrl">
        <p style="padding-top:15px;">URL Factura Electrónica:<br> <a :href="responseUrl" target="_blank">{{ responseUrl }}</a></p>
        <img :src="qrCodeUrl" alt="QR Code" />
        <br><button style="margin-top:15px;" @click="downloadQR" class="btn btn-primary">Descargar QR</button>
      </div>
    </div>
  </Layout>
</template>
